<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12" lg="3" sm="6">
        <numeric-statistic
          :label-color="colors[0].backgroundColor"
          :stat-percent="`${percent(stats[0].value)} %`"
          :title="stats[0].value"
          label="Creados"
          label-text-color="#5e5e5e"
          stat-color="#546E7A"
          stat-icon="mdi-finance"
          sub-title="Orden de trabajo"
        />
      </v-col>
      <v-col cols="12" lg="3" sm="6">
        <numeric-statistic
          :label-color="colors[1].backgroundColor"
          :stat-color="colors[1].backgroundColor"
          :stat-percent="`${percent(stats[1].value)} %`"
          :title="stats[1].value"
          label="En ejecución"
          stat-icon="mdi-finance"
          sub-title="Orden de trabajo"
        />
      </v-col>
      <v-col cols="12" lg="3" sm="6">
        <numeric-statistic
          :label-color="colors[2].backgroundColor"
          :stat-color="colors[2].backgroundColor"
          :stat-percent="`${percent(stats[2].value)} %`"
          :title="stats[2].value"
          label="Finalizados"
          stat-icon="mdi-finance"
          sub-title="Orden de trabajo"
        />
      </v-col>
      <v-col cols="12" lg="3" sm="6">
        <numeric-statistic
          :label-color="colors[3].backgroundColor"
          :stat-color="colors[3].backgroundColor"
          :stat-percent="`${percent(stats[3].value)} %`"
          :title="stats[3].value"
          label="Aprobados"
          stat-icon="mdi-finance"
          sub-title="Orden de trabajo"
        />
      </v-col>
      <v-col cols="12" lg="3" sm="6">
        <numeric-statistic
          :label-color="colors[4].backgroundColor"
          :stat-color="colors[4].backgroundColor"
          :stat-percent="`${percent(stats[4].value)} %`"
          :title="stats[4].value"
          label="Rechazados"
          stat-icon="mdi-finance"
          sub-title="Orden de trabajo"
        />
      </v-col>
      <v-col cols="12" lg="3" sm="6">
        <numeric-statistic
          :label-color="colors[5].backgroundColor"
          :stat-color="colors[5].backgroundColor"
          :stat-percent="`100 %`"
          :title="total"
          label="Total"
          stat-icon="mdi-finance"
          sub-title="Orden de trabajo"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-card>
          <v-card-title>
            <span
              class="text-h6"
              v-text="'Ordenes de trabajo del último mes'"
            />
          </v-card-title>
          <v-card-text>
            <v-data-table
              :headers="headers"
              :items="orders"
              :loading="loadingItems"
              :options.sync="options"
              :server-items-length="serverItemsLength"
              disable-sort
            >
              <template v-slot:[`item.orderType`]="{ item }">
                <v-chip class="ma-2 white--text" color="teal" label>
                  <v-avatar left>
                    <v-icon>{{ orderTypes[item.orderType - 1].icon }}</v-icon>
                  </v-avatar>
                  {{ orderTypes[item.orderType - 1].value }}
                </v-chip>
              </template>
              <template v-slot:[`item.state`]="{ item }">
                <v-chip
                  :color="colors[item.state - 1].backgroundColor"
                  :text-color="colors[item.state - 1].fontColor"
                  class="pa-3 font-weight-bold"
                  label
                  small
                >
                  {{ $t(`state.${orderState[item.state - 1]}`) }}
                </v-chip>
              </template>
            </v-data-table>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import NumericStatistic from "@/components/widgets/statistic/NumericStatistic";
import { mapActions, mapGetters, mapState } from "vuex";

export default {
  name: "Dashboard",
  components: { NumericStatistic },
  data: () => ({
    options: {},
    filter: {
      page: 1,
    },
    loadingItems: false,
    serverItemsLength: 0,
    colors: [
      { backgroundColor: "#D1DADE", fontColor: "black" },
      { backgroundColor: "#F8AC59", fontColor: "white" },
      { backgroundColor: "#0AB1F0", fontColor: "white" },
      { backgroundColor: "#ED5565", fontColor: "white" },
      { backgroundColor: "#6D4C41", fontColor: "white" },
      { backgroundColor: "#000000", fontColor: "white" },
    ],
    headers: [
      {
        text: "Código",
        value: "code",
      },
      {
        text: "Nombre del cliente",
        value: "client",
      },
      {
        text: "Líder de Orden de trabajo",
        value: "leader",
      },

      {
        text: "Estado",
        value: "state",
      },
      {
        text: "Tipo",
        value: "orderType",
        width: 300,
      },
    ],
  }),
  computed: {
    ...mapState("library", ["orders", "stats", "orderTypes", "orderState"]),
    ...mapGetters("library", ["total"]),
  },
  watch: {
    options: {
      handler() {
        const { page, itemsPerPage } = this.options;
        this.filter.pageSize = itemsPerPage;
        this.filter.page = page;
        this.loadingItems = true;
        this.fetchOrders(this.filter)
          .then((value) => (this.serverItemsLength = value))
          .finally(() => (this.loadingItems = false));
      },
      deep: true,
    },
  },
  created() {
    this.fetchStats();
  },
  methods: {
    ...mapActions("library", ["fetchOrders", "fetchStats"]),
    percent(value) {
      return this.total === 0 ? 0 : Math.round((value * 100) / this.total);
    },
  },
};
</script>

<style scoped></style>
