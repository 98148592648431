<script>
export default {
  name: "ClientOrderSearchBar",
  props: {
    value: { type: Object, required: true },
  },
  data() {
    return {
      filter: this.value,
      stateFilter: [
        { name: "Creado", id: "1" },
        { name: "En ejecución", id: "2" },
        { name: "Finalizado", id: "3" },
        { name: "Aprobado", id: "4" },
        { name: "Rechazado", id: "5" },
      ],
    };
  },
};
</script>
<template>
    <v-row no-gutters>
      <v-col cols="12" md="2" class="flex-grow-1 flex-shrink-1">
        <v-text-field
          v-model="filter.code"
          label="Código"
          class="pr-md-1"
          outlined
          dense
          @keyup.enter="$emit('submit', filter)"
        />
      </v-col>
      <v-col cols="12" md="3" class="flex-grow-1 flex-shrink-1">
        <v-text-field
          v-model="filter.localName"
          label="Local"
          class="pr-md-1"
          outlined
          dense
          @keyup.enter="$emit('submit', filter)"
        />
      </v-col>
      <v-col cols="12" md="2" class="flex-grow-1 flex-shrink-1">
        <v-select
          v-model="filter.state"
          :items="stateFilter"
          item-text="name"
          item-value="id"
          label="Estado"
          class="pr-md-1"
          outlined
          dense
        ></v-select>
      </v-col>
      <v-col cols="12" md="4" class="flex-grow-1 flex-shrink-1">
        <v-row no-gutters>
          <v-btn
            color="default"
            depressed
            outlined
            class="mr-2"
            @click="$emit('clear')"
          >
            Limpiar
          </v-btn>
          <v-btn color="primary" depressed @click="$emit('submit', filter)">
            Buscar
          </v-btn>
        </v-row>
      </v-col>
    </v-row>    
</template>