<template>
    <if-auth>
        <template  v-slot:allowed={cliente}>
            <component :is="cliente ? 'DashboardClient' : 'Dashboard'"></component>
        </template>
    </if-auth>
</template>

<script>
import IfAuth from '@/components/auth/IfAuth'
import Dashboard from '@/views/dashboard/Dashboard'
import DashboardClient from '@/views/dashboard/DashboardClient'

export default {
    name: 'DashboardC',
    components: { IfAuth, Dashboard, DashboardClient },
}
</script>