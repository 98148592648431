/**
 * Sets query params for a given URL
 * It adds new query params, updates existing params with a new value and removes params with value null/undefined
 *
 * @param {Object} params The query params to be set/updated
 * @param {String} url The url to be operated on
 * @param {Boolean} clearParams Indicates whether existing query params should be removed or not
 * @param {Boolean} railsArraySyntax When enabled, changes the array syntax from `keys=` to `keys[]=` according to Rails conventions
 * @returns {String} A copy of the original with the updated query params
 */
export const setUrlParams = (
    params,
    url = window.location.href,
    clearParams = false,
    railsArraySyntax = false,
    decodeParams = false,
) => {
    const urlObj = new URL(url);
    const queryString = urlObj.search;
    const searchParams = clearParams ? new URLSearchParams('') : new URLSearchParams(queryString);

    Object.keys(params).forEach((key) => {
        if (params[key] === null || params[key] === undefined) {
            searchParams.delete(key);
        } else if (Array.isArray(params[key])) {
            const keyName = railsArraySyntax ? `${key}[]` : key;
            if (params[key].length === 0) {
                searchParams.delete(keyName);
            } else {
                params[key].forEach((val, idx) => {
                    if (idx === 0) {
                        searchParams.set(keyName, val);
                    } else {
                        searchParams.append(keyName, val);
                    }
                });
            }
        } else {
            searchParams.set(key, params[key]);
        }
    });

    urlObj.search = decodeParams
        ? decodeURIComponent(searchParams.toString())
        : searchParams.toString();

    return urlObj.toString();
};

/**
 * This function accepts the `name` of the param to parse in the url
 * if the name does not exist this function will return `null`
 * otherwise it will return the value of the param key provided
 *
 * @param {String} name
 * @param {String?} urlToParse
 * @returns value of the parameter as string
 */
export const getParameterByName = (name, query = window.location.search) => {
    return queryToObject(query)[name] || null;
};

// Returns a decoded url parameter value
// - Treats '+' as '%20'
function decodeUrlParameter(val) {
    return decodeURIComponent(val.replace(/\+/g, '%20'));
}

/**
 * Convert search query into an object
 *
 * @param {String} query from "document.location.search"
 * @param {Object} options
 * @param {Boolean?} options.gatherArrays - gather array values into an Array
 * @param {Boolean?} options.legacySpacesDecode - (deprecated) plus symbols (+) are not replaced with spaces, false by default
 * @returns {Object}
 *
 * ex: "?one=1&two=2" into {one: 1, two: 2}
 */
export function queryToObject(query, { gatherArrays = false, legacySpacesDecode = false } = {}) {
    const removeQuestionMarkFromQuery = String(query).startsWith('?') ? query.slice(1) : query;
    return removeQuestionMarkFromQuery.split('&').reduce((accumulator, curr) => {
        const [key, value] = curr.split('=');
        if (value === undefined) {
            return accumulator;
        }

        const decodedValue = legacySpacesDecode ? decodeURIComponent(value) : decodeUrlParameter(value);
        const decodedKey = legacySpacesDecode ? decodeURIComponent(key) : decodeUrlParameter(key);

        if (gatherArrays && decodedKey.endsWith('[]')) {
            const decodedArrayKey = decodedKey.slice(0, -2);

            if (!Array.isArray(accumulator[decodedArrayKey])) {
                accumulator[decodedArrayKey] = [];
            }

            accumulator[decodedArrayKey].push(decodedValue);
        } else {
            accumulator[decodedKey] = decodedValue;
        }

        return accumulator;
    }, {});
}

/**
 * Merges a URL to a set of params replacing value for
 * those already present.
 *
 * Also removes `null` param values from the resulting URL.
 *
 * @param {Object} params - url keys and value to merge
 * @param {String} url
 * @param {Object} options
 * @param {Boolean} options.spreadArrays - split array values into separate key/value-pairs
 * @param {Boolean} options.sort - alphabetically sort params in the returned url (in asc order, i.e., a-z)
 */
export function mergeUrlParams(params, url, options = {}) {
    const { spreadArrays = false, sort = false } = options;
    const re = /^([^?#]*)(\?[^#]*)?(.*)/;
    let merged = {};
    const [, fullpath, query, fragment] = url.match(re);

    if (query) {
        merged = query
            .substr(1)
            .split('&')
            .reduce((memo, part) => {
                if (part.length) {
                    const kv = part.split('=');
                    let key = decodeUrlParameter(kv[0]);
                    const value = decodeUrlParameter(kv.slice(1).join('='));
                    if (spreadArrays && key.endsWith('[]')) {
                        key = key.slice(0, -2);
                        if (!Array.isArray(memo[key])) {
                            return { ...memo, [key]: [value] };
                        }
                        memo[key].push(value);

                        return memo;
                    }

                    return { ...memo, [key]: value };
                }

                return memo;
            }, {});
    }

    Object.assign(merged, params);

    const mergedKeys = sort ? Object.keys(merged).sort() : Object.keys(merged);

    const newQuery = mergedKeys
        .filter((key) => merged[key] !== null && merged[key] !== undefined)
        .map((key) => {
            let value = merged[key];
            const encodedKey = encodeURIComponent(key);
            if (spreadArrays && Array.isArray(value)) {
                value = merged[key]
                    .map((arrayValue) => encodeURIComponent(arrayValue))
                    .join(`&${encodedKey}[]=`);
                return `${encodedKey}[]=${value}`;
            }
            return `${encodedKey}=${encodeURIComponent(value)}`;
        })
        .join('&');

    if (newQuery) {
        return `${fullpath}?${newQuery}${fragment}`;
    }
    return `${fullpath}${fragment}`;
}

export function updateHistory({ state = {}, title = '', url, replace = false, win = window } = {}) {
    if (win.history) {
        if (replace) {
            win.history.replaceState(state, title, url);
        } else {
            win.history.pushState(state, title, url);
        }
    }
}