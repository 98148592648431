<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12">
        <v-card elevation="3">
          <v-card-text flat elevation="3">
            <client-order-search-bar
              v-model="newFilter"
              @clear="clearFilter"
              @submit="fetchRecords"
            />
            <v-data-table
              :footer-props="{
                showFirstLastPage: true,
                'items-per-page-options': [5, 10, 15, 50],
              }"
              :headers="headers"
              :items="orders"
              :loading="loadingItems"
              :options.sync="options"
              :items-per-page="itemsPerPage"
              :server-items-length="serverItemsLength"
              disable-sort
              
            >
              <template v-slot:[`item.ot`]="{ item }">
                <div style="display: flex 1 auto; padding: .5rem 0; margin-right: 1rem;">
                  <div class="font-weight-bold">
                    {{ item.code }} | {{ item.equipment.code }} | {{ item.client }} | {{ item.office.name }}
                  </div>
                  <div>
                    <i class="mdi mdi-account-supervisor"></i> {{ item.leader }} <span> · </span> 
                    <i class="mdi mdi-calendar"></i> {{ formatDate(item.startDate) }}</div>
                </div>
              </template>
              <template v-slot:[`item.orderType`]="{ item }">
                <v-chip class="ma-2 white--text" color="teal" label>
                  <v-avatar left>
                    <v-icon>{{ orderTypes[item.orderType - 1].icon }}</v-icon>
                  </v-avatar>
                  {{ orderTypes[item.orderType - 1].value }}
                </v-chip>
              </template>
              <template v-slot:[`item.status`]="{ item }">
                <v-chip
                  :color="colors[item.state - 1].backgroundColor"
                  :text-color="colors[item.state - 1].fontColor"
                  class="pa-3"
                  label
                  small
                >
                  <span class="font-weight-bold">{{
                    $t(`state.${orderState[item.state - 1]}`)
                  }}</span>
                </v-chip>
              </template>
              <template v-slot:[`item.actions`]="{ item }">
                <v-tooltip v-if="item.state === 3 || item.state === 4" top>
                <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      v-bind="attrs"
                      class="ma-1"
                      color="orange"
                      depressed
                      fab
                      x-small
                      v-on="on"
                      @click="viewPdf(item)"
                    >
                      <v-icon color="white" small>mdi-file-pdf-box</v-icon>
                    </v-btn>
                </template>
                <span>Pdf</span>
                </v-tooltip>
              </template>

            </v-data-table>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { format } from "date-fns";
import { mapState } from "vuex";
import { queryToObject } from "@/util/url_utility";
import ClientOrderSearchBar from './ClientOrderSearchBar.vue';

export default {
  name: "DashboardClient",
  components: { ClientOrderSearchBar, },
  inject: ["clientRepository"],
  data() {
    const queryParams = queryToObject(window.location.search);
    const { page = "1", pageSize = "10" } = queryParams;
    return {
      orders: [],
      loadingItems: false,
      serverItemsLength: 0,
      options: {
        page: parseInt(page, 10),
      },
      itemsPerPage: parseInt(pageSize, 10),
      newFilter: {
        page: parseInt(page, 10),
        pageSize: parseInt(pageSize, 10),
        code: queryParams.code,
        officeName: queryParams.officeName,
        state: queryParams.state,
      },
      colors: [
        { backgroundColor: "#D1DADE", fontColor: "black" },
        { backgroundColor: "#F8AC59", fontColor: "white" },
        { backgroundColor: "#0AB1F0", fontColor: "white" },
        { backgroundColor: "#ED5565", fontColor: "white" },
        { backgroundColor: "#6D4C41", fontColor: "white" },
        { backgroundColor: "#000000", fontColor: "white" },
      ],
      headers: [
        {
          text: "Órdenes de trabajo",
          value: "ot"
        },
        {
          text: "Estado",
          value: "status"
        },
        {
          text: "Trabajo a realizar",
          value: "orderType",
          width: 40 * 6 + 32,
        },
        {
          text: "Acciones",
          value: "actions",
          width: 40 * 1 + 32,
        },
      ],
    };
  },
  computed: {
    ...mapState("library", ["orderTypes", "orderState"]),
    query() {
      return Object.assign({}, this.newFilter);
    },
  },
  watch: {
    options: {
      handler() {
        const { page, itemsPerPage } = this.options;
        this.newFilter.pageSize = itemsPerPage;
        this.newFilter.page = page;
        this.fetchRecords(this.newFilter);
      },
      deep: true,
    },
  },
  methods: {
    formatDate(v) {
      return format(new Date(v), "yyyy-MM-dd HH:mm");
    },
    fetchRecords(newFilter) {
      this.loadingItems = true;
      return this.clientRepository.orders(newFilter)
        .then(({pagination, result}) => {
          this.orders = result
          this.serverItemsLength = pagination.totalItems;
        })
        .finally(() => {
          this.loadingItems = false;
        });
    },
    clearFilter() {
      Object.assign(this.newFilter, { code: null, localName: null, state: null });
      this.fetchRecords(this.newFilter);
    },
    viewPdf(item) {
      window.open(
        `${process.env.VUE_APP_BASE_API}/pdf/${item.id}/file`,
        "_blank"
      );
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
