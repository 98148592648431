var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',{attrs:{"elevation":"3"}},[_c('v-card-text',{attrs:{"flat":"","elevation":"3"}},[_c('client-order-search-bar',{on:{"clear":_vm.clearFilter,"submit":_vm.fetchRecords},model:{value:(_vm.newFilter),callback:function ($$v) {_vm.newFilter=$$v},expression:"newFilter"}}),_c('v-data-table',{attrs:{"footer-props":{
              showFirstLastPage: true,
              'items-per-page-options': [5, 10, 15, 50],
            },"headers":_vm.headers,"items":_vm.orders,"loading":_vm.loadingItems,"options":_vm.options,"items-per-page":_vm.itemsPerPage,"server-items-length":_vm.serverItemsLength,"disable-sort":""},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.ot",fn:function(ref){
            var item = ref.item;
return [_c('div',{staticStyle:{"display":"flex 1 auto","padding":".5rem 0","margin-right":"1rem"}},[_c('div',{staticClass:"font-weight-bold"},[_vm._v(" "+_vm._s(item.code)+" | "+_vm._s(item.equipment.code)+" | "+_vm._s(item.client)+" | "+_vm._s(item.office.name)+" ")]),_c('div',[_c('i',{staticClass:"mdi mdi-account-supervisor"}),_vm._v(" "+_vm._s(item.leader)+" "),_c('span',[_vm._v(" · ")]),_c('i',{staticClass:"mdi mdi-calendar"}),_vm._v(" "+_vm._s(_vm.formatDate(item.startDate)))])])]}},{key:"item.orderType",fn:function(ref){
            var item = ref.item;
return [_c('v-chip',{staticClass:"ma-2 white--text",attrs:{"color":"teal","label":""}},[_c('v-avatar',{attrs:{"left":""}},[_c('v-icon',[_vm._v(_vm._s(_vm.orderTypes[item.orderType - 1].icon))])],1),_vm._v(" "+_vm._s(_vm.orderTypes[item.orderType - 1].value)+" ")],1)]}},{key:"item.status",fn:function(ref){
            var item = ref.item;
return [_c('v-chip',{staticClass:"pa-3",attrs:{"color":_vm.colors[item.state - 1].backgroundColor,"text-color":_vm.colors[item.state - 1].fontColor,"label":"","small":""}},[_c('span',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(_vm.$t(("state." + (_vm.orderState[item.state - 1])))))])])]}},{key:"item.actions",fn:function(ref){
            var item = ref.item;
return [(item.state === 3 || item.state === 4)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"ma-1",attrs:{"color":"orange","depressed":"","fab":"","x-small":""},on:{"click":function($event){return _vm.viewPdf(item)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"white","small":""}},[_vm._v("mdi-file-pdf-box")])],1)]}}],null,true)},[_c('span',[_vm._v("Pdf")])]):_vm._e()]}}],null,true)})],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }