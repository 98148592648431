<template>
  <v-card class="ibox" tile>
    <v-card-title class="pa-1 ibox-title">
      <v-spacer />
      <v-chip
        :color="labelColor"
        :text-color="labelTextColor"
        class="pa-3"
        label
        small
      >
        {{ label }}
      </v-chip>
    </v-card-title>
    <v-card-text class="ibox-content">
      <div class="text-h4 font-weight-regular no-margins" v-text="title" />
      <div
        class="stat-percent font-weight-bold"
        :style="statColor ? statStyle : null"
      >
        {{ statPercent }}
        <v-icon small>
          {{ statIcon }}
        </v-icon>
      </div>
      <small>{{ subTitle }}</small>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: "NumericStatistic",
  props: {
    label: { type: String, required: true },
    subTitle: { type: String, required: true },
    title: { type: Number, required: true },
    statPercent: { type: String, required: true },
    statIcon: { type: String, required: true },
    statColor: { type: String, required: true },
    labelColor: { type: String, default: "primary" },
    labelTextColor: { type: String, default: "white" },
    hover: { type: Boolean, default: false },
  },
  data: (vm) => ({
    statStyle: {
      color: vm.statColor,
    },
  }),
};
</script>

<style lang="sass" scoped>
.ibox
  margin-top: 0
  padding: 0

.ibox-title
  background-color: #ffffff
  border-color: #e7eaec
  border-image: none
  border-style: solid solid none
  border-width: 3px 0 0
  color: inherit
  margin-bottom: 0
  padding: 14px 15px 7px
  min-height: 48px

.ibox-content
  background-color: white
  color: inherit
  padding: 15px 20px 20px 20px
  border-color: #e7eaec
  border-image: none
  border-style: solid solid none
  border-width: 1px 0

.stat-percent
  float: right

  & > i
    color: inherit
</style>